<template>
<v-dialog v-model="showDialog" persistent class="mx-auto" max-width="800">
    <v-card height="100%" min-height="400" elevation="3" flat color="grey lighten-4">
        <v-card-title class="text-h5 red darken-4">
            <v-toolbar dark dense flat class="transparent">

                <v-toolbar-title>Textures Gallery</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn color="white darken-1" icon dark text @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-0 h-100">

            <v-card flat>
                <v-tabs color="deep-red accent-4" right>
                    <v-tab in>Wood</v-tab>
                    <v-tab>Marble</v-tab>
                    <v-tab>Concrete</v-tab>

                    <v-tab-item v-for="group in groups" :key="group">
                        <v-container fluid class="pa-4">
                            <v-row>
                                <v-col v-for="item in items.filter((m)=>m.group === group)" :key="item" cols="12" md="4" class="pa-2 elevation-2">
                                    <v-img :src="item.icon" :lazy-src="item.icon"  aspect-ratio="1/2" @click="selectTexture(item)" style="cursor:pointer"></v-img>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
            </v-card>

          

        </v-card-text>

    </v-card>
</v-dialog>
</template>

<script>
 require('../../../public/textures/Wood-floor-parquet-bright-texture-3d-BPR-free-download-seamless-HD-4K-render-full.jpg')
 require('../../../public/textures/marble-floor-texture-3d-tile-bpr-material-free-download-full-preview.jpg')
 require('../../../public/textures/Wall-Concrete-BPR-material-3D-texture-High-Resolution-Free-Download-4K-preview-full.jpg')
export default {
    props: {
        showDialog: {
            Type: Boolean,
            Default: false
        }
    },

    data() {
        return {
            dialog: false,
            selectedItem: 1,
            items: [
                { text: 'Wood', icon: require('../../../public/textures/Wood-floor-parquet-bright-texture-3d-BPR-free-download-seamless-HD-4K-render-full.jpg'),url:'./textures/Wood-floor-parquet-bright-texture-3d-BPR-free-download-seamless-HD-4K-render-full.jpg', group: 'wood' },
                { text: 'Wood', icon: require('../../../public/textures/Wood-floor-parquet-texture-3d-BPR-free-download-seamless-HD-4K-render-full.jpg'),url:'/textures/Wood-floor-parquet-texture-3d-BPR-free-download-seamless-HD-4K-render-full.jpg', group: 'wood' },
                { text: 'Wood', icon: require('../../../public/textures/Wood-floor-parquet-brown-texture-3d-BPR-free-download-seamless-HD-4K-render-full.jpg'),url:'./textures/Wood-floor-parquet-brown-texture-3d-BPR-free-download-seamless-HD-4K-render-full.jpg', group: 'wood' },

                { text: 'Cermic', icon: require('../../../public/textures/Grey-Granite-Marble-PBR-texture-3D-free-download-High-resolution-Unity-Unreal-Vray-render-full.jpg'),url:'./textures/Grey-Granite-Marble-PBR-texture-3D-free-download-High-resolution-Unity-Unreal-Vray-render-full.jpg', group: 'marble' },
                { text: 'Wood', icon: require('../../../public/textures/marble-floor-texture-3d-tile-bpr-material-free-download-full-preview.jpg'),url:'./textures/marble-floor-texture-3d-tile-bpr-material-free-download-full-preview.jpg', group: 'marble' },
                { text: 'Ceramic', icon: require('../../../public/textures/White-Marble-PBR-texture-3D-free-download-High-resolution-Unity-Unreal-Vray-render-full.jpg'),url:'./textures/White-Marble-PBR-texture-3D-free-download-High-resolution-Unity-Unreal-Vray-render-full.jpg', group: 'marble' },

                { text: 'Cermic', icon: require('../../../public/textures/Concrete-wall-BPR-material-3D-texture-High-Resolution-Free-Download-4K-render-full-preview-864x566.jpg'),url:'./textures/Concrete-wall-BPR-material-3D-texture-High-Resolution-Free-Download-4K-render-full-preview-864x566.jpg', group: 'concrete' },
                { text: 'Wood', icon: require('../../../public/textures/Wall-Concrete-BPR-material-3D-texture-High-Resolution-Free-Download-4K-preview-full.jpg'),url:'./textures/Wall-Concrete-BPR-material-3D-texture-High-Resolution-Free-Download-4K-preview-full.jpg', group: 'concrete' },
                { text: 'Ceramic', icon: require('../../../public/textures/Concrete-panel-precast-BPR-material-3D-texture-High-Resolution-Free-Download-4K-full-render-864x648.jpg'),url:'./textures/Concrete-panel-precast-BPR-material-3D-texture-High-Resolution-Free-Download-4K-full-render-864x648.jpg', group: 'concrete' }

            ],
            groups:['wood','marble','concrete']

        }
    },

    methods: {
        closeDialog() {
            this.$emit('close-dialog')

        },
        selectTexture(item) {
         
            this.$emit('change-texture', item.url)

        },
    },
}
</script>
