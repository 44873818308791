<template>
<v-card height="100%" color="transparent" tile>
    <v-card-text class=" h-100">
        <v-container>
            <v-parallax dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="300">
                <v-row align="center" justify="center">
                    <v-col class="text-center" cols="12">
                        <h1 class="text-h4 font-weight-thin mb-4">
                            WE PROVIDE A SEAMLESS EXPERIENCE REAL ESTATE VISIT
                        </h1>
                        <h2 class="subheading">
                            New immersive digital experiences through interactive 3D virtual content
                        </h2>
                    </v-col>
                </v-row>
            </v-parallax>
            <v-card color="transparent" flat>
                <v-card-text class="pt-6">

                    <v-row>
                        <v-col v-for="(item, n) in items" :key="n" cols="12" md="4" class="pa-4">
                            <v-card class="mx-auto" max-width="420" elevation="3">
                                <v-img class="white--text align-end" height="300" :lazy-src="item.imageUrl" :src="item.imageUrl" aspect-ratio="1/2">
                                    <v-card-title style="background-color: #000000c2;">{{item.name}}</v-card-title>
                                    <v-card-text class="" style="background-color: #000000c2;">
                                        <div>{{item.address}}</div>
                                        <div>{{item.description}}</div>
                                    </v-card-text>
                                </v-img>
                                <v-card-actions class="pa-4 white">
                                    <v-btn small link color="grey draken-4" exact text @click="openModelUrl()">
                                        <span class="text-capitalize">See more details</span>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn large color="red darken-3 pl-2 pr-2" class="text-capitalize title" flat tile elevation="0" dark @click="importModel(item)">
                                        <v-icon class="pr-2" size="22">mdi-rotate-3d</v-icon> virtual tour
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
            <v-row>

            </v-row>

        </v-container>
    </v-card-text>
</v-card>
</template>

<script>
export default {
    computed: {
        items() {
            return this.$store.getters.estateList
        }
    },

    methods: {
        /** Open details url */
        openModelUrl(item) {
            window.open(item.url, "_blank")
        },
        /** start import model */
        importModel(item) {
            //select the model
            this.$store.commit('selectEstate', item)
            this.$router.push({ name: 'Viewer' })
        }
    },
}
</script>
