import Vue from 'vue'
import Vuex from 'vuex'
import estateDS from '../data/ds.json'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    estateList: estateDS,
    currentEstate: null,
    loadingModelState: {
      progress: 0,
      state: 0,
    },
  },
  getters: {
    estateList(state) {
      return state.estateList
    },
    currentEstate(state) {
      return state.currentEstate
    },
    loadingModelState(state) {
      return state.loadingModelState
    }
  },
  mutations: {
    selectEstate(state, item) {
      // mutate state
      state.currentEstate = item;
    },
    updateLoadingState(state, item) {
      // mutate state
      state.loadingModelState = item;
      console.log(state.loadingModelState)
    }
  },
  actions: {
  },
  modules: {
  }
})
