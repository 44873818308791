
import Vue from 'vue';
import  selectTextureModel from '@/components/dialogs/selectTextureModel.vue'
export default Vue.extend({
    name: 'App',
components:{
    selectTextureModel
},
    data: () => ({
        //
    }),
    mounted() {
        document.title = "Showroom"
    },
    methods: {
        openModelsView() {
            this.$store.commit("selectEstate", null);
           this.$router.push({ name: 'Home' })
        },
        openContactus()
        {
            window.open("https://www.immobalcaen.be/fr/Contact","_blank")
        }
    }
});
